body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background: #000;
}
.container {
    max-width: 45rem; /* Equivalent to 720px if base font size is 16px */
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
}
.header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 45rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem; /* Use consistent padding */
    z-index: 1000;

    flex-wrap: wrap;
}
.dropdown {
    position: relative;
    display: inline-block;
    color: white;

    max-width: calc(100% - 3rem); /* Subtracting space for the logo */
}

.dropdown-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: max-content;
    padding: 7px 12px;
    font-size: 13px;
    cursor: pointer;
    gap: 0.25rem;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 100px;
}

.lang-zh-cn #selected-lang {
    font-size: 12px; /* Slightly smaller font size for Chinese */
    letter-spacing: -0.5px; /* Tighten letter spacing */
}

#selected-lang {
    margin-right: 0.25em;
    font-weight: 700;
}

.dropdown-arrow {
    width: 0.8em;
    height: 0.8em;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: calc(100% + 10px); /* Add 10px gap */
    left: 0;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(42px);

    max-width: calc(
        100vw - 3rem
    ); /* Subtracting 3rem to account for container padding */
    width: max-content;
    right: 0; /* Align to the right side of the dropdown */
    left: auto; /* Remove left alignment */
}

.dropdown-option {
    padding: 0.5rem 0.75rem;
    max-height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    font-weight: 500;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.dropdown-option:hover {
    background: rgba(255, 255, 255, 0.08);
}

.dropdown.active .dropdown-content {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
}

.dropdown.active .dropdown-arrow {
    transform: rotate(180deg);
}

.image-wrapper {
    display: flex;
    flex: 0 0 auto;

    position: relative;
    z-index: 1;
}
.image-wrapper img {
    max-width: 100%;
    display: inline-block;
    margin: 0 auto;
    position: relative;
}
.header.sticky {
    position: fixed;
}

.video-button-overlay {
    aspect-ratio: 16 / 9;
    width: 82.3%;
    top: 32.8%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
}

.video-button-overlay.ko {
    top: 37.45%;
}

.video-button-overlay.en {
    top: 34.8%;
}

.video-button-overlay.ja {
    top: 35.8%;
}

.video-button-overlay.zh-cn,
.video-button-overlay.zh-tw {
    top: 36.1%;
}

.video-button-overlay.recruit-en {
    top: 11.12%;
}

.video-button-overlay.recruit-ja {
    top: 11.6%;
}

.video-button-overlay.recruit-ko {
    top: 12%;
}

.video-button-overlay.recruit-zh-cn,
.video-button-overlay.recruit-zh-tw {
    top: 12.05%;
}

.video-button {
    width: 100%;
    height: 99%;
    background-image: url(https://img.youtube.com/vi/6TFYhR3yCKk/0.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    padding: 0;
}

.video-play-svg path:first-child {
    fill: #212121;
    fill-opacity: 0.8;
    transition:
        fill 0.3s ease,
        fill-opacity 0.3s ease;
}

.video-button:hover .video-play-svg path:first-child {
    fill: #ff0000;
    fill-opacity: 1;
}

.video-play-svg path:last-child {
    fill: #ffffff;
}

.submit-anchor-overlay {
    z-index: 10;
    background-color: #0000;
    width: 74%;
    height: 4.2%;
    position: absolute;
    bottom: 26.4%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 24px;
}

#yt-player {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 99%;
    height: 99%;
    z-index: 10;
    transition: none;
}

.grid-container {
    aspect-ratio: 1;
    grid-template-rows: repeat(2, 0.8fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    width: 83%;
    display: grid;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.4rem 0;
    z-index: 10;
}

.grid-item {
    cursor: pointer;
    align-self: stretch;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 100px;
}

.grid-container.lang-ko {
    gap: 1rem;
    height: 3.9%;
    top: 57.85%;
}
.grid-container.lang-en {
    height: 3.65%;
    top: 57.85%;
    gap: 1rem;
}
.grid-container.lang-ja {
    gap: 1rem;
    height: 3.9%;
    top: 58.25%;
}
.grid-container.lang-zh-cn,
.grid-container.lang-zh-tw {
    gap: 1rem;
    height: 3.9%;
    top: 59%;
}

.faq-container,
.faq-button,
.help-btn,
.faq-image-container {
    display: block;
    margin: 0;
    padding: 0;
    max-width: 100%;
}

.back-button {
    cursor: pointer;
}

.header-left {
    display: flex;
    gap: 10px;
}

#faq-image {
    max-width: 100%;
}

.mama-image-wrapper {
    position: relative;
    width: 100%; /* Take full container width */
    height: auto; /* Maintain aspect ratio */
}

.mama-image-wrapper img {
    width: 100%; /* Image takes full wrapper width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any default inline spacing */
    max-width: 100%; /* Never exceed screen width */
}

.mama-button {
    position: absolute;
    width: 300px;
    height: 60px;
    background: transparent;
    cursor: pointer;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
}

/* Position adjustments for each language */
.mama-button.ko {
    bottom: 17.5%;
    width: 64%;
    height: 10%;
}

.mama-button.en {
    bottom: 15%;
    width: 64%;
    height: 10%;
}

.mama-button.ja {
    bottom: 18.5%;
    width: 64%;
    height: 10%;
}

.mama-button.zh-cn {
    bottom: 17.5%;
    width: 64%;
    height: 10%;
}

.mama-button.zh-tw {
    bottom: 16.5%;
    width: 64%;
    height: 10%;
}

@media (max-width: 450px) {
    .video-button-overlay.en {
        top: 34.5%;
    }

    .video-button-overlay.ko {
        top: 37.2%;
    }

    .video-button-overlay.zh-cn,
    .video-button-overlay.zh-tw {
        top: 36%;
    }

    .grid-container.lang-ko {
        top: 57.8%;
        height: 3.8%;
        gap: 0.6rem;
    }
    .grid-container.lang-en {
        top: 57.85%;
        height: 3.65%;
        gap: 0.6rem;
    }
    .grid-container.lang-ja {
        gap: 0.6rem;
        height: 3.9%;
        top: 58.28%;
    }
    .grid-container.lang-zh-cn,
    .grid-container.lang-zh-tw {
        gap: 0.6rem;
        height: 3.9%;
        top: 59%;
    }
}
