body {
  background: #000;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  box-sizing: border-box;
  max-width: 45rem;
  margin: 0 auto;
  position: relative;
}

.header {
  box-sizing: border-box;
  z-index: 1000;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 45rem;
  padding: 1rem 1.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown {
  color: #fff;
  max-width: calc(100% - 3rem);
  display: inline-block;
  position: relative;
}

.dropdown-select {
  cursor: pointer;
  backdrop-filter: blur(4px);
  background: #ffffff26;
  border-radius: 100px;
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
  max-width: max-content;
  padding: 7px 12px;
  font-size: 13px;
  display: flex;
}

.lang-zh-cn #selected-lang {
  letter-spacing: -.5px;
  font-size: 12px;
}

#selected-lang {
  margin-right: .25em;
  font-weight: 700;
}

.dropdown-arrow {
  width: .8em;
  height: .8em;
}

.dropdown-content {
  z-index: 1;
  backdrop-filter: blur(42px);
  background: #ffffff1f;
  border-radius: 12px;
  width: max-content;
  min-width: 100%;
  max-width: calc(100vw - 3rem);
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: auto;
  right: 0;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-option {
  cursor: pointer;
  color: #fff6;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  align-items: center;
  width: 100px;
  max-height: 2rem;
  padding: .5rem .75rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  overflow: hidden;
}

.dropdown-option:hover {
  background: #ffffff14;
}

.dropdown.active .dropdown-content {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
}

.dropdown.active .dropdown-arrow {
  transform: rotate(180deg);
}

.image-wrapper {
  z-index: 1;
  flex: none;
  display: flex;
  position: relative;
}

.image-wrapper img {
  max-width: 100%;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.header.sticky {
  position: fixed;
}

.video-button-overlay {
  aspect-ratio: 16 / 9;
  width: 82.3%;
  margin: 0;
  position: absolute;
  top: 32.8%;
  left: 50%;
  transform: translateX(-50%);
}

.video-button-overlay.ko {
  top: 37.45%;
}

.video-button-overlay.en {
  top: 34.8%;
}

.video-button-overlay.ja {
  top: 35.8%;
}

.video-button-overlay.zh-cn, .video-button-overlay.zh-tw {
  top: 36.1%;
}

.video-button-overlay.recruit-en {
  top: 11.12%;
}

.video-button-overlay.recruit-ja {
  top: 11.6%;
}

.video-button-overlay.recruit-ko {
  top: 12%;
}

.video-button-overlay.recruit-zh-cn, .video-button-overlay.recruit-zh-tw {
  top: 12.05%;
}

.video-button {
  cursor: pointer;
  background-image: url("https://img.youtube.com/vi/6TFYhR3yCKk/0.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  width: 100%;
  height: 99%;
  padding: 0;
  transition: transform .3s;
}

.video-play-svg path:first-child {
  fill: #212121;
  fill-opacity: .8;
  transition: fill .3s, fill-opacity .3s;
}

.video-button:hover .video-play-svg path:first-child {
  fill: red;
  fill-opacity: 1;
}

.video-play-svg path:last-child {
  fill: #fff;
}

.submit-anchor-overlay {
  z-index: 10;
  background-color: #0000;
  border-radius: 24px;
  width: 74%;
  height: 4.2%;
  position: absolute;
  bottom: 26.4%;
  left: 50%;
  transform: translateX(-50%);
}

#yt-player {
  z-index: 10;
  width: 99%;
  height: 99%;
  transition: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-container {
  aspect-ratio: 1;
  z-index: 10;
  grid-template-rows: repeat(2, .8fr);
  grid-template-columns: repeat(2, 1fr);
  gap: .6rem;
  width: 83%;
  padding: .4rem 0;
  display: grid;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grid-item {
  cursor: pointer;
  z-index: 1;
  border-radius: 100px;
  align-self: stretch;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

.grid-container.lang-ko {
  gap: 1rem;
  height: 3.9%;
  top: 57.85%;
}

.grid-container.lang-en {
  gap: 1rem;
  height: 3.65%;
  top: 57.85%;
}

.grid-container.lang-ja {
  gap: 1rem;
  height: 3.9%;
  top: 58.25%;
}

.grid-container.lang-zh-cn, .grid-container.lang-zh-tw {
  gap: 1rem;
  height: 3.9%;
  top: 59%;
}

.faq-container, .faq-button, .help-btn, .faq-image-container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.back-button {
  cursor: pointer;
}

.header-left {
  gap: 10px;
  display: flex;
}

#faq-image {
  max-width: 100%;
}

.mama-image-wrapper {
  width: 100%;
  height: auto;
  position: relative;
}

.mama-image-wrapper img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.mama-button {
  cursor: pointer;
  background: none;
  width: 300px;
  height: 60px;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}

.mama-button.ko {
  width: 64%;
  height: 10%;
  bottom: 17.5%;
}

.mama-button.en {
  width: 64%;
  height: 10%;
  bottom: 15%;
}

.mama-button.ja {
  width: 64%;
  height: 10%;
  bottom: 18.5%;
}

.mama-button.zh-cn {
  width: 64%;
  height: 10%;
  bottom: 17.5%;
}

.mama-button.zh-tw {
  width: 64%;
  height: 10%;
  bottom: 16.5%;
}

@media (width <= 450px) {
  .video-button-overlay.en {
    top: 34.5%;
  }

  .video-button-overlay.ko {
    top: 37.2%;
  }

  .video-button-overlay.zh-cn, .video-button-overlay.zh-tw {
    top: 36%;
  }

  .grid-container.lang-ko {
    gap: .6rem;
    height: 3.8%;
    top: 57.8%;
  }

  .grid-container.lang-en {
    gap: .6rem;
    height: 3.65%;
    top: 57.85%;
  }

  .grid-container.lang-ja {
    gap: .6rem;
    height: 3.9%;
    top: 58.28%;
  }

  .grid-container.lang-zh-cn, .grid-container.lang-zh-tw {
    gap: .6rem;
    height: 3.9%;
    top: 59%;
  }
}
/*# sourceMappingURL=index.3eea9f45.css.map */
